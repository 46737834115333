.page--404 {

    @include respond-max(tablet) {
        background: $grey-4;
    }
    
    header {
        border: 0;
    }

    .content-header {
        justify-content: center;

        &::before {
            display: none;
        }

        @include respond-max(tablet) {
            background: $grey-4;
            margin-bottom: 0;
            padding-bottom: 0;
        }

        .media-obj { 

            picture {
                place-content: center;

                img {
                    width: 100%;
                    max-width: 226px;
                }
            }
        }

        &__btn {
            justify-content: space-between;
            flex-direction: row;
            width: auto;
            min-width: auto;
            margin: 0 auto 20px auto;
            padding: 15px 25px;
            
            @include respond-min(tablet) {
                margin: 0 10px;
            }
        }
        &__title {
            margin: 60px 0 24px 0;
            text-align: center;
            font-size: 36px;
        }
        &__caption {
            margin: 0 0 40px 0;
            text-align: center;
        }
        &__buttons {
            display: flex;
            justify-content: center;
            flex-direction: column;
            
            @include respond-min(tablet) {
                flex-direction: row;
            }
        }
    }

    .desktop-gray { 

        @include respond-min(tablet) {
            background: $l-grey;
        }
    }

    .main-content {
        margin-top: 0;
    }

    .slider-main--centered {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .footer--404 {
        font-size: 10px;
        line-height: 12px;
        color: rgba($color: $grey-5, $alpha: 0.6);
        padding-top: 30px;
        padding-bottom: 40px;
        
        @include respond-min(tablet) {
            padding-top: 70px;
            padding-bottom: 120px;
        }
                
        .footer__disclaimer {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;

        & a:focus, & a:hover {
            font-weight: bold;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        & .footer__disclaimer_inner.desktop {
            display: none;
        }

        & .footer__disclaimer_under {
            margin: 20px 0;
            flex-direction: column;
            p {
            font-size: 1.8rem;
            @include respond-min(tablet) {
                font-size: 1rem;
            }
            }
        }

        p {
            margin-bottom: 1.5rem;
            font-size: 1.125rem;
            line-height: 1.2;
            color: rgba($color: $grey-5, $alpha: 0.6);

            &:last-of-type {
            margin-bottom: 0;
            }
        }

        @include respond-min(tablet) {
            width: 100%;
            margin-bottom: 0;
            padding-right: 2.5rem;

            .footer__disclaimer_inner.desktop {
            display: block;
            column-count: 2;
            }

            p {
            margin-bottom: 1rem;
            font-size: 0.625rem;
            letter-spacing: 1px;
            }
        }
        }

    }

    .products__slider-wrapper {
        display: flex;
        width: max-content;
        grid-gap: 0;
    }

    .slider-mobile {
        overflow-x: auto;
    }

    .products__actual {
        width: 390px;

        @include respond-max(tablet) {
            width: 315px;
        }
    }

    .opif__product {
        display: flex;
        flex-direction: column;
        margin-right: 48px;
        width: 390px;
        height: auto;
        text-decoration: none;
        
        @include respond-max(tablet) {
            margin: 0 3rem 0 2.5rem;
            width: unset;
        }

        &--image {
            height: 50%;

            img {
                object-fit: cover;
            }
        }
        &--inner {
            height: 50%;
            font-size: 28px;
            line-height: 120.6%;
            letter-spacing: 0.04em;
            color: $white;
            padding: 45px 30px;
            background: $red;
            width: 390px;
            
            @include respond-max(tablet) {
                width: 315px;
            }
        }
    }
}